<template>
<div >
     <vxe-toolbar>
          <template #buttons>
            <vxe-button icon="fa fa-plus" @click="insertEvent()">新增</vxe-button>
            <!-- <vxe-input v-model="filterNametext" type="search" placeholder="试试全表搜索" @keyup="searchEvent1"></vxe-input> -->
            <div  style=" margin-top: 1px;color: red;: crimson; ">请尝试滚动鼠标滚轮，查看记录！</div>
          </template>
        </vxe-toolbar>

        <vxe-table
          border
          resizable
          
          
          stripe
          show-overflow
          highlight-hover-row
          ref="xTable"
          height="600"
          :data="tableData"
         >
          <vxe-table-column type="seq" width="60"></vxe-table-column>
          <vxe-table-column field="供应商编码" title="供应商代码" width="150"  sortable></vxe-table-column>
          <vxe-table-column field="供应商名称" title="供应商名称" sortable></vxe-table-column>
          <vxe-table-column field="手机" title="手机" ></vxe-table-column>
          <vxe-table-column field="名字" title="姓名"></vxe-table-column>
          <vxe-table-column field="供应商有效期" title="系统有效期"  ></vxe-table-column>
          <vxe-table-column field="邀请码" title="邀请码" ></vxe-table-column>
          <vxe-table-column field="邀请码有效期" title="邀请码失效"   ></vxe-table-column>
          <vxe-table-column field="是否启用" title="是否启用"  ></vxe-table-column>
          <vxe-table-column title="操作" width="150" show-overflow>
            <template #default="{ row }">
              <vxe-button type="text" icon="vxe-icon--edit-outline" @click="editEvent(row)"></vxe-button>
              <vxe-button type="text" icon="vxe-icon--close" @click="removeEvent(row)"></vxe-button>
               <vxe-button type="text"  @click="openewm(row)">二维码</vxe-button>
            </template>
          </vxe-table-column>
        </vxe-table>
      
         <el-dialog
    title="编辑/新增"
         :visible.sync='showEdit'
         width="50%">

  <el-form ref="form" :model="formData" :rules="rules" label-width="110px">
  <el-form-item label="id">
    <el-col :span="9">
    <el-input :disabled=true v-model="formData.id"></el-input>
    </el-col>
  </el-form-item>
 <el-form-item label="供应商" prop="供应商">
   <el-col :span="12">
     <el-select :disabled="dialog_gys" filterable   v-model="formData.supplierName" style="width:100%"  @change="assignmentLab">
       <el-option
      v-for="item in suppilerOptions"
      :key="item.supcust_no"
      :label="item.sup_name+item.supcust_no"
      :value="item.supcust_no">
    </el-option>
       </el-select>
   </el-col>
  <el-col :span="9">
    <a style="padding-left:5px;">{{formData.supplierCode}}</a>
  </el-col>
  </el-form-item>
  <el-form-item label="到期日" prop="expiryDate">
    <el-col :span="9"><el-date-picker
      v-model="formData.expiryDate"
      style="width:100%"
      align="right"
      type="date"
      placeholder="选择日期"
      :picker-options="pickerOptions">
    </el-date-picker></el-col>
    
  </el-form-item>


  <el-form-item label="邀请码" prop="code">
     <el-col :span="9">
    <el-input v-model="formData.code" style="width:100%"></el-input>
     </el-col>
<el-col :span="1"><div style="padding-left:5px;">
    <el-button @click.prevent="addCode()">添加</el-button>
    </div>
     </el-col>
  </el-form-item>
<el-form-item label="邀请码失效时间">
     <el-col :span="6">
    <el-date-picker style="width:100%"
      v-model="formData.codeEnd.date"
      type="date"
      >
      </el-date-picker>
     </el-col>
      <el-col :span="6">
        <div style="padding-left:5px;">
    <el-time-select style="width:100%"
      v-model="formData.codeEnd.time"
      :picker-options="{
    start: '00:00',
    step: '00:30',
    end: '23:59'
  }"     >
      </el-time-select>
        </div>
     </el-col>
    
  </el-form-item>
  <el-form-item label="是否启用">
    <el-col>
        <el-checkbox v-model="formData.isuse">启用</el-checkbox>
    </el-col>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit">保存</el-button>

    <el-button :disabled="showewmbut" @click="dialogVisible=true">打开二维码</el-button>
  </el-form-item>
  <el-form-item>

  </el-form-item>


              </el-form>
         </el-dialog>
         <el-dialog
   :title="formData.supplierName"
   :visible.sync="dialogVisible"
   width="540px"
   @opened="openQrCode"
   :append-to-body="true"
>
  <div class="qrcode">
     <div id="qrcode" />
  </div>
  <div class="qrcodeBtn">
    <el-button type="primary" @click="saveQrcode">保存到本地</el-button>
  </div>
</el-dialog>

</div>
</template>
<script>
 import  md5 from '../components/common/js/allSchool.js'
 import {PostobjKeySort}  from '../components/common/js/printHelp.js'
 import {dateFormat} from '@/components/common/js/printHelp.js'
 //import vueQr from 'vue-qr'
  //import QRcode from '@xkeshi/vue-qrcode';
  import QRCode from 'qrcodejs2'
  
  import { defineComponent, reactive } from 'vue'
    import XEUtils from 'xe-utils'


  
export default{
 
    data(){
    
        return{

          filterNametext:'',
          Editrow:{
            supplierCode:'',
            code:'',
            supplierName:''
          },
          showewmbut:true,
          dialogVisible: false,
          QrShow:false,
          dialog_gys:false,
          ///时间快捷方式
          pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '明天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '明年',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
              picker.$emit('pick', date);
            }
          }]
        },

          suppilerOptions:[{supcust_no:'',sup_name:''},{supcust_no:'100011',sup_name:'立白代理商'}],
            submitLoading: false,
            showEdit:false, //显示编辑
            selectRow : null,
        tableData:[{id:"156581",供应商名称:"立白洗衣粉",供应商编码:"10015",手机:"18960929657",名字:"张三",供应商有效期:"2021-12-1",邀请码:"457861",邀请码有效期:"2021-11-12 23:59:59",是否启用:"true" }],
   
        formData: {
                id:null,
                supplierCode: null,
                supplierName: null,
                tel: null,
                userName: null,
                Operdate: null,
                expiryDate: null,
                code: '123',
                codeEnd:{date:'',time:''},
                },
       
        
          
          qrCodeUrl:"https://open.weixin.qq.com/connect/oauth2/authorize?" +
                "appid="+  Gold.reg_appid+
                "&redirect_uri=http%3A%2F%2Fwww.thsen.top%2Fweixin_reg"+
                "&response_type=code" +
                "&scope=snsapi_userinfo" +
                "&state=123"+
                "#wechat_redirect",
                           
                

             
        rules: {
                    expiryDate: [{ required: true, message: '使用有效期', trigger: 'blur' }],
                    code: [{ required: true, message: '请输入邀请码', trigger: 'blur' }],
                    供应商: [{required: true,message: '请选择供应商', trigger: 'blur' }],
                }



        }
    },
    components:{
    //qrcode : QRcode

    },

    mounted(){
          this.getBindedSupList();
          this.getSupList();
          
          },
    methods:{

      //  searchEvent1 () {
     
      //         const filterName = XEUtils.toValueString(this.filterNametext).trim().toLowerCase()
      //         if (filterName) {
      //           const filterRE = new RegExp(filterName, 'gi')
      //           const searchProps = ['供应商名称', '供应商编码']
      //           const rest = newList.filter(item => searchProps.some(key => XEUtils.toValueString(item[key]).toLowerCase().indexOf(filterName) > -1))
      //           newList = rest.map(row => {
      //             const item = Object.assign({}, row)
      //             searchProps.forEach(key => {
      //               item[key] = XEUtils.toValueString(item[key]).replace(filterRE, match => `<span class="keyword-lighten">${match}</span>`)
      //             })
      //             return item
      //           })
      //         } else {
      //         this.tableData= newList
      //         }
      //       },



      openQrCode() {
      document.getElementById('qrcode').innerHTML = ''
      const qrcode = new QRCode('qrcode', {
        width: 300,
        height: 300,
        correctLevel:3
      })
      let qcode="https://open.weixin.qq.com/connect/oauth2/authorize?" +
                "appid="+  Gold.reg_appid+
                "&redirect_uri=http%3A%2F%2Fwww.thsen.top%2Fweixin_reg"+
                "&response_type=code" +
                "&scope=snsapi_userinfo" +
                "&state="+this.formData.code+"|"+this.formData.supplierName
                "#wechat_redirect",
      qrcode.makeCode(qcode)
    },
    saveQrcode() {
      //找到canvas标签
      const myCanvas = document.getElementById('qrcode').getElementsByTagName('canvas')
      //创建一个a标签节点
      const a = document.createElement('a')
      //设置a标签的href属性（将canvas变成png图片）
      a.href = myCanvas[0].toDataURL('image/png').replace('image/png', 'image/octet-stream')
      //设置下载文件的名字
      a.download = "("+this.formData.supplierCode+")"+this.formData.supplierName+".png"
      //点击
      a.click()
    },
      getBindedSupList(){
       console.log("supplierEdit ==>",this.$store.state.ip,this.$store.state.unionid,this.$store.state.currentMartId)
     let query= {
                     ip:this.$store.state.ip,
                     scm_code:this.$store.state.currentMartId,
                     unionid: this.$store.state.unionid
                   }
                 
                   let newkey = PostobjKeySort(query)
                  
                   let sign= md5.M5(newkey.md5s)
                    let url="/SCM/GetSCMSupOperInfo?sign="
                   this.reqM1Service(url+sign,newkey.par,'post') 
                    //this.$axios.post(url+sign,newkey.par)
                    .then((res => {
                      console.log(res.data)
                      this.tableData=res.data.data 

                    }))                  
      },
      getSupList(){
        let query= {
                     ip:this.$store.state.ip,
                     scm_code:this.$store.state.currentMartId,
                     unionid: this.$store.state.unionid
                   }
                 
                   let newkey = PostobjKeySort(query)
                  
                   let sign= md5.M5(newkey.md5s)
                    let url="/SCM/GetSupList?sign="
                  this.reqM1Service(url+sign,newkey.par,'post')
                   //this.$axios.post(url+sign,newkey.par)
                    .then((res => {
                      console.log("供应商列表",res.data.data) 
          this.suppilerOptions=res.data.data
          //this.suppilerOptions = (this.suppilerOptions)
          var  len = this.suppilerOptions.length;
    for (var i = 0; i < len-1; i++) {
        for (var j = 0; j < len - 1 - i; j++) {
            // 相邻元素两两对比，元素交换，大的元素交换到后面
            // if (this.suppilerOptions[j]["supcust_no"] < list[j + 1]["supcust_no"] ) {
            //   //	< 为升序排列，降序请换 >
            //     var temp = this.suppilerOptions[j];
            //     this.suppilerOptions[j] = this.suppilerOptions[j+1];
            //     this.suppilerOptions[j+1] = temp;
            // }
        }
    }




                    }))                  
      },
      //保存编辑
        onSubmit(){
          
            console.log("isuse ",this.formData.isuse)
              for (var key in this.formData)
                   { 
                     if(key!="id" && key!="tel" && key!="userName"){
                       console.log(key,this.formData[key])
                       var item=this.formData[key]
                       if (item===""){
                         alert("需要填写的内容不完整!")
                         return
                       }
                     }
                      
                   }


            let isu=0;
            if(this.formData.isuse===true){
                  isu=1
            }
           let query= {
                      id: this.formData.id,
                      valid_date:dateFormat('YYYY-mm-dd',this.formData.expiryDate),
                     ip:this.$store.state.ip,
                     scm_code:this.$store.state.currentMartId,
                     unionid: this.$store.state.unionid,
                     sup_no:this.formData.supplierCode,
                     sup_name: this.formData.supplierName,                    
                     isEnable:isu,
                     verify:this.formData.code,
                     verify_date: dateFormat('YYYY-mm-dd',this.formData.codeEnd.date) +" "+this.formData.codeEnd.time+":00"
                   }
                   console.log("query is" ,query)
                

                  let newkey = PostobjKeySort(query)
                  
                   let sign= md5.M5(newkey.md5s)
                   console.log("par is " ,newkey.par)
                    let url="/SCM/SetSCMSupOper?sign="
                    this.reqM1Service(url+sign,newkey.par,'post')
                    //this.$axios.post(url+sign,newkey.par)
                    .then((res => {
                      console.log("i post res 保存后返回结果------ ", res.data)
                      if(res.data.code===200){
                        alert("保存成功!")
                        this.tableData=res.data.data;
                        this.showEdit=false;
                      }
                      else{
                        alert("保存失败!")
                      }
                      
                    }))

        },

      ///将供应商代码传递给lab
      assignmentLab(value){
        console.log("传入的是 ",value)
        let ob = {}
        ob=this.suppilerOptions.find((item)=>{
          return item.supcust_no=== value
        })
        console.log("我是",ob.supcust_no)
        this.formData.supplierCode=ob.supcust_no
        this.formData.supplierName=ob.sup_name
4
        //this.formData.supplierCode=la;
      },
        insertEvent(){
            this.dialog_gys=false;
            this.formData={
                id:'',
                supplierCode: '',
                supplierName:'',
                tel:'',
                userName:'',             
                expiryDate:'',
                code:'',
                isuse:true,
                codeEnd:{date:new Date(),time:'23:59'}
            }
            this.showEdit = true;
            this.showewmbut=true

        },
        ///修改行值
        editEvent(row){
          console.log("zheg ",typeof(row.邀请码有效期))
            this.dialog_gys=true;
           this.showEdit = true
         this.formData={
                id:row.id,
                supplierCode: row.供应商编码,
                supplierName:row.供应商名称,
                tel:row.手机,
                userName:row.名字,
                isuse:row.是否启用,
                expiryDate:new Date(Date.parse(row.供应商有效期.replace(/-/g,  "/"))),
                code:row.邀请码,              
                codeEnd:{date:new Date(Date.parse(row.邀请码有效期.substring(0,10).replace(/-/g,  "/"))),                              
                time:row.邀请码有效期.substr(row.邀请码有效期.lastIndexOf("T") + 1,5)}
            }

            this.edit_qrcode(this.formData.code);
           this.QrShow=true;
           this.showewmbut=false;
        },
      
        ///打开二维码
        openewm(row){
          this.formData={}
          this.formData={
            supplierCode:row.供应商编码,
            code:row.邀请码,
            supplierName:row.供应商名称,
            id:null,
               
                tel: null,
                userName: null,
                Operdate: null,
                expiryDate: null,
             
                codeEnd:{date:'',time:''},
          }
          this.dialogVisible=true;
       
        },
        submitEvent(){

        },
       addCode(){
       if(this.formData.supplierName!="")  {
          this.formData.code=md5.makeCode(6);
         this.QrShow=true;
         this.edit_qrcode(this.formData.code);
         this.showewmbut=false;
       }
       else{
         alert("请先添加供应商！")
       }
        
       },
    edit_qrcode(code){
                this.qrCodeUrl= "https://open.weixin.qq.com/connect/oauth2/authorize?" +
                "appid="+  Gold.reg_appid+
                "&redirect_uri=http%3A%2F%2Fwww.thsen.top%2Fweixin_reg"+
                "&response_type=code" +
                "&scope=snsapi_userinfo" +
                "&state="+code
                "#wechat_redirect"
    }

    }
}

</script>
<style>

</style>

