<template>
    <div>
<span ico="el-icon-s-unfold" class='daohangcaidang' @click="changecollagese">{{collapse}}</span>
<el-menu  class="el-menu-vertical-demo"
:unique-opened="true"
 @select="handls"  default-active="1-1-1"  :collapse="isCollapse">
     <el-submenu v-for="it in ui_menu" :key='it.submenu' :index="it.submenu">
          <template slot="title">
               <i :class="it.ico"></i>
                <span  slot="title">{{it.span}}</span>
          </template>
          <!-- 没有找到原因，这样将修改密码的index写死2-1-1   -->
           <el-menu-item  v-for ='it0 in it.menu_item' :key='it0.index' index="2-1-1">{{it0.title}}</el-menu-item>


          <el-submenu v-for="it1 in it.group"  :key='it1.index' :index="it1.index">
              <template slot="title">{{it1.title}}</template>
              <el-menu-item  v-for="it2 in it1.menu_item" :key='it2.index' :index="it2.index">{{it2.title}}</el-menu-item>
          </el-submenu>
     </el-submenu>
</el-menu>
</div>
</template>
<style>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }
</style>

<script>
    ////将右边的页面先引入
    import store  from '../store'
    import supplierEdit from '@/components/supplierEdit.vue'
    import PasswordEdit from '@/components/PasswordEdit.vue'

    export default {
        data() {
            return {
                 isCollapse: true,
                 collapse:'导航菜单  ◀',
                payedit:true,
                ui_menu:
                [{"submenu":"1","ico":'el-icon-user-solid',"span":'管理',"menu_item":[],"group":[
                    {"index":'1-1',"title":'供应商管理',"menu_item":[{"index":'1-1-1' ,'title':'编辑供应商',"content":supplierEdit}
                                                                   ] }]},
                {"submenu":"2","ico":'el-icon-s-tools',"span":'系统管理',"menu_item":
                [{"index":'2-1-1' ,'title':'修改密码',"content":PasswordEdit},] ,"group":[] }
                ]
                ,
                //是否导航展开
                isCollapse: false
            };
        },
        created(){
           let user= this.userinfo.userinfo;
           //this.getlimits(user);

        },
        computed: {
                userinfo : {
                    get () {
                        return this.$store.state.userName
                    }
                },
                editableTabs : {
                get () {
                    return this.$store.state.editableTabs
                }
            },
                },
        methods: {
            changecollagese(){this.isCollapse=!this.isCollapse;
            if(this.isCollapse==true){this.collapse='展开 ▶';
            store.commit('changebodywidth',"width: 100%")
            console.log('bodyWidth',this.$store.state.bodyWidth)
            }
            else{this.collapse='导航菜单 ◀';
            store.commit('changebodywidth',"width: 85%")
            console.log('bodyWidth',this.$store.state.bodyWidth)
            }


            },
            getlimits(s){
                this.reqM1Service('/limits/',{user:s},'get')
               //this.$axios.get('/limits/?user='+s)
               .then((res=>{
                  if(res.status==200){
                      if (res.data.Msg[0].打开==true){
                          this.payedit=false;
                      }
                  } }
                   ))
            },
                 handls(index){

                this.ui_menu.forEach(a=>{

                    if(a.group.length>0)
                    {a.group.forEach(b=>{
                        b.menu_item.forEach(c=>{
                           if(c.index==index) {
                             //  console.log("我是c",c)
                               if (this.editableTabs.length>7){
                                  alert("不能再打开窗口了，先关闭没有使用的窗口！");

                                   return;
                               }
                               store.commit('addtabs',c)
                           }
                        })
                       if (b.index ==index) {
                            console.log("ok"+b.title);
                            if(this.editableTabs.length>7){
                                alert("不能再打开窗口了，先关闭没有使用的窗口！");
                                return;
                            }
                            store.commit('addtabs',b)
                        }
                    })}

                    if(a.menu_item.length>0)
                    { console.log('now check',a.span,index)
                    if (index==='2-1-1'){
                         a.menu_item.forEach(d=>{
                        if(d.index=index)
                {  console.log("我是d",d)
                  store.commit('addtabs',d)}
                    })
                    }
                       }
                })
            }
    }}
</script>
