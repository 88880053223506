<template>
<div  :style="m_width">


<el-tabs v-model="vdtval" type="card" closable @tab-remove="removeTab" style="height:100%">
    <el-tab-pane
            v-for="(item) in editableTabs"
            :key="item.name"
            :label="item.title"
            :name="item.name">
        <component  :is=item.content  :title="item.title" :parameter="item.parameter"></component>
    </el-tab-pane>


</el-tabs>
        </div>
</template>
<script>
    /*import {mapState} from 'vuex'  bbb*/
    import store  from '../store'
    export default {
        computed:{
            editableTabs : {
                get () {
                    return this.$store.state.editableTabs
                }

            },
            vdtval : {
                get () {
                    return this.$store.state.editableTabsValue
                },
                set (val) {
                   this.$store.state.editableTabsValue=val
                }
            },
             handler () {
                     return this.$store.state.bodyWidth

    }
            },
        data() {
            return {
                m_width:'width:100%'

            }
        },

        watch:{
            handler(newVal,oldVal){
                this.m_width=newVal;
                console.log( 'bottom wathch bodyWidth',this.$store.state.bodyWidth)
                return;
            }

        },
        methods: {

            removeTab(targetName) {
                console.log('我现在移走',targetName);
                store.commit('remtabs',{

                    targetName:targetName
                })

            }
        }
    }
</script>
<style>

.el-tabs__content{
    height: 100%;
}
.el-tab-pane{
    height: 100%;
}
.el-tabs__nav-scroll {

    height: 35px;
}
</style>
