
<template>
<div style="height: 95%;width: 99%;">
    <el-dialog
    @close="dia_close"
    title="修改密码"
         :visible.sync='showdia'
         width="40%">
         <el-form  label-width="80px" :model="formLabelAlign">
  <el-form-item label="新密码">
    <el-input v-model="formLabelAlign.password" show-password style="width:200px"></el-input>
  </el-form-item>
  <el-form-item label="确认密码">
    <el-input v-model="formLabelAlign.repeat" show-password style="width:200px"></el-input>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit">确认</el-button>
    <el-button @click="showdia=false">取消</el-button>
  </el-form-item>
</el-form>

    </el-dialog>
</div>
</template>
<script>
 import store  from '../store'
 import {objKeySort}  from '../components/common/js/printHelp.js'
 import  md5  from '../components/common/js/allSchool.js'

export default{
     components: {
         objKeySort
         },
    methods:{
        onSubmit(){
            if (this.formLabelAlign.repeat!=this.formLabelAlign.password || this.formLabelAlign.repeat==='')
            {
                alert("输入的信息有误！");
                return;
            }
            let par={
               
               //supcustNo:this.$store.state.superCode,
               unionid:this.$store.state.unionid,
               ip:this.$store.state.ip,
               password:this.formLabelAlign.password

            }
            let sign=this.submitRequest(par)
            let url='/SCM/ChangePassword?sign='
               this.reqM1Service(url+sign,par,'post')
           // this.$axios.post(url+sign,par)
           .then((res=>{
                 console.log(res)
                if(res.data.code===200){
                    alert("审核成功！")
                   this.showdia=false;
            }}))
        },
        submitRequest(Pa){
            var newkey = objKeySort(Pa)
             let par={};
              let md5s='';

              for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }
             let sign= md5.M5(md5s)
             return sign

        },
        dia_close(){
            console.log("关闭自己")
            store.commit('remtabs',{targetName:'2-1-1'})
        }
    },
    data(){
        return{
            showdia:true,
            formLabelAlign:{
                repeat:'',
                password:''

            }
        }
    }

}

</script>
<style>

</style>