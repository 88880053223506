<template>
<div  style="height: 100%;">
         <el-row type="flex" class="row-bg" justify="space-between">
  <el-col class="grid-content title" :span="12" >
     <span style="line-height:64px; padding-left: 10px;">
          v2023-04-18-002
      </span>
      </el-col>      
  <el-col class="grid-content title" :span="12">
      <div style="text-align:right;">
      <span  style="line-height:64px; padding-right: 10px;  ">        
          当前用户：{{gy}}
          <el-button icon="el-icon-right" circle type="warning" @click="relogin"></el-button>   
      </span>
      </div>
      </el-col>
</el-row>
<el-container style="height: 95%; border: 1px solid #eee;" >

        <Left></Left>
         <Bottom></Bottom>
</el-container>


</div>
</template>
<style>
 .title {
background-color:#00C4FF ;
/*
background: url(../assets/img/head1.png) no-repeat;
*/
height: 70px;
width: 100%;
background-size: cover;

}
.xx{
   height: 100%;
}

    .el-header {
        background-color: #B3C0D1;
        color: #333;
        line-height: 60px;
    }

    .el-aside {
        color: #333;
    }
</style>

<script>
    import Left from '@/components/Left_control.vue'
    import Bottom from '@/components/Bottom.vue'
    export default {
        computed:{

            gy ()
                {
                    console.log("get ",this.$store.state.userName,this.$store.currentMartId)
                    return this.$store.state.userName
                }},
        components: {
            Left,
            Bottom
        },
        data() {

            return {

            }
        }
        ,
        methods:{
           relogin(){
               this.$router.push("./control_login")
           }
        }
    };
</script>
